import { Button, Modal, Space, Typography, notification } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import CryptoJS from 'crypto-js';

import { COLORS } from 'style';
import { ExamAttempt, ExamQuestion, PayloadType, SectionGroup } from 'type';
import moment from 'moment';
import { useHistory } from 'react-router';
import { createLtsExamAttemptRequest } from 'features/examAttemptSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CountDown from './CountDown';
import { KEYS } from 'const';
import { isMobile } from 'utils';

const { Title, Text } = Typography;

type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  item: SectionGroup;
};

const KEY = 'hdd-2024';

const ModalSectionGroup: React.FunctionComponent<Props> = ({
  isModalOpen,
  handleCancel,
  item,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { creatingLtsExamAttempt } = useSelector(
    (state: RootState) => state.examAttemptSlice
  );

  const { sections, name, examInfo } = item;
  const examQuestions = (examInfo ?? []).map((item) =>
    _.get(item, `examQuestions[0]`, null)
  );

  const sectionsUnExam = (examInfo ?? [])
    .filter(
      (v) => _.get(v, 'attempts[0]', { status: 'active' })?.status !== 'done'
    )
    .map((v) => _.get(v, 'section._id', ''))
    .filter((v) => v !== '');

  const key = sections
    .reduce((prev, curr) => prev + `${curr}-`, '')
    .replace(/-$/, '');
  const examQuestion = _.get(
    examInfo,
    '[0].examQuestions[0]',
    null
  ) as ExamQuestion | null;

  const textButton = checkStatusGroupSection();

  function checkStatusGroupSection(): string {
    if (
      moment().unix() < moment(examQuestion?.startTime ?? new Date()).unix()
    ) {
      return 'Mở sau';
    }

    if (moment().unix() < moment(examQuestion?.endTime ?? new Date()).unix()) {
      return 'Bắt đầu làm bài';
    }
    return 'Đã đóng';
  }

  const handleDownloadExamAttempts = (): void => {
    if (sectionsUnExam.length === 0) {
      handleCancel();
      return;
    }
    const payload: PayloadType = {
      other: sectionsUnExam,
      callback: {
        success(data) {
          console.log('data: ', data);
          try {
            const ltsExamAttempts = localStorage.getItem(KEYS.ltsExamAttempts);
            const arrEmpty = CryptoJS.AES.encrypt(
              JSON.stringify({}),
              KEY
            ).toString();
            const bytes = CryptoJS.AES.decrypt(
              ltsExamAttempts ?? arrEmpty,
              KEY
            );
            const parseBytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            parseBytes[`${key}`] = data.map((item: ExamAttempt) => ({
              ...item,
              isAnswerInOrder: _.get(
                examQuestion,
                'fieldSettings[0].isAnswerInOrder',
                false
              ),
            }));
            localStorage.setItem(
              KEYS.ltsExamAttempts,
              CryptoJS.AES.encrypt(JSON.stringify(parseBytes), KEY).toString()
            );
            history.push(
              `/contestant/exam-attempts/${key}?name=${name}&isEnd=${
                sections.length > 1 && sectionsUnExam.length === 1
              }`
            );
          } catch (error) {
            notification.error({
              message: `${error}`,
            });
            // console.log(error);
          }
        },
        failed(mess) {
          try {
            const ltsExamAttempts = localStorage.getItem(KEYS.ltsExamAttempts);
            const arrEmpty = CryptoJS.AES.encrypt(
              JSON.stringify({}),
              KEY
            ).toString();
            const bytes = CryptoJS.AES.decrypt(
              ltsExamAttempts ?? arrEmpty,
              KEY
            );
            const parseBytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (
              !!(key in parseBytes) &&
              typeof parseBytes[`${key}`] === 'object'
            ) {
              // 649169e1fa186c00120633c8
              history.push(
                `/contestant/exam-attempts/${key}?name=${name}&isEnd=${
                  sections.length > 1 && sectionsUnExam.length === 1
                }`
              );
              return;
            }
          } catch (error) {
            notification.error({
              message: `${error}`,
            });
            //
          }
          notification.error({
            message: `${mess}`,
          });
        },
      },
    };
    dispatch(createLtsExamAttemptRequest(payload));
  };

  const startExam = (): void => {
    if (textButton === 'Đã đóng') {
      notification.warning({
        message: 'Không thể tham gia vào phần thi!',
      });
    } else {
      handleDownloadExamAttempts();
    }
  };

  let totalPoint = 0;
  let totalQuestionAmount = 0;
  let totalTimeDo = 0;

  examQuestions.forEach((eQ) => {
    let tmp1 = 0;
    let tmp2 = 0;
    let tmp3 = 0;
    (eQ?.fieldSettings ?? []).forEach((item) => {
      const pointEachQuestion = _.get(item, 'pointEachQuestion', 0);
      const questionAmount = _.get(item, 'questionAmount', 0);
      const isAnswerInOrder = _.get(item, 'isAnswerInOrder', true);
      const attachQuestion = _.get(item, 'attachQuestion', 1);
      const timeDo = _.get(item, 'timeEachQuestion', 0) * questionAmount;

      tmp1 += questionAmount * pointEachQuestion;
      if (!isAnswerInOrder) {
        tmp2 += questionAmount / attachQuestion;
      } else {
        tmp2 += questionAmount;
      }
      tmp3 += timeDo;
    });
    totalPoint += tmp1;
    totalQuestionAmount += tmp2;
    totalTimeDo += tmp3;
  });

  const minutes = Math.floor(totalTimeDo / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (totalTimeDo % 60).toString().padStart(2, '0');

  const timeLeft = moment(examQuestion?.startTime ?? new Date()).diff(
    moment(),
    'seconds'
  );

  const renderTotalTime = (): React.ReactNode => {
    const totalTime = (examInfo ?? [])
      .filter((v) => _.get(v, 'attempts[0]', undefined)?.status === 'done')
      .map((v) => _.get(v, 'attempts[0]', undefined))
      .reduce((prev, curr) => {
        const time = _.get(curr, 'totalTime', 0);
        return prev + time;
      }, 0);
    const totalScore = (examInfo ?? [])
      .filter((v) => _.get(v, 'attempts[0]', undefined)?.status === 'done')
      .map((v) => _.get(v, 'attempts[0]', undefined))
      .reduce((prev, curr) => {
        const scored = _.get(curr, 'totalScore', 0);
        return prev + scored;
      }, 0);
    const minutes = Math.floor(totalTime / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (totalTime % 60).toString().padStart(2, '0');
    if (
      (examInfo ?? []).filter(
        (v) => _.get(v, 'attempts[0]', undefined)?.status === 'done'
      ).length === 1
    )
      return;
    return (
      <table style={{ color: '#595959' }}>
        <tbody>
          <tr>
            <td
              style={{
                padding: '0rem 0rem',
                fontWeight: 600,
                fontSize: '0.78rem',
                minWidth: 180,
              }}
            >
              Tổng thời gian thi
            </td>
            <td style={{ fontWeight: '600', color: '#2b68a9' }}>
              {minutes} phút {seconds} giây
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: '0rem 0rem',
                fontWeight: 600,
                fontSize: '0.78rem',
                minWidth: 180,
              }}
            >
              Tổng điểm đạt được
            </td>
            <td style={{ fontWeight: '600', color: '#2b68a9' }}>
              {Number(totalScore.toFixed(2))} điểm
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      style={{ maxWidth: isMobile() ? '90vw' : 420 }}
      bodyStyle={{
        borderRadius: '0.3rem',
        padding: '1rem',
      }}
      maskStyle={{
        borderRadius: '0.3rem',
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <Title
        style={{
          textAlign: 'center',
          color: COLORS.TEXT.yellowGrey,
          marginBottom: 0,
        }}
        className="text-center"
        level={5}
      >
        {name}
      </Title>
      {sectionsUnExam.length === 0 ? (
        <Space
          direction="vertical"
          style={{ width: '100%', paddingTop: 12 }}
          align="center"
        >
          <p
            style={{
              color: COLORS.TEXT.yellowGrey,
              fontWeight: '600',
              textTransform: 'uppercase',
              fontSize: '0.8rem',
              marginBottom: 0,
              textAlign: 'center',
            }}
          >
            Chúc mừng bạn đã hoàn thành bài thi!
          </p>
          {(examInfo ?? [])
            .filter(
              (v) => _.get(v, 'attempts[0]', undefined)?.status === 'done'
            )
            .map((v) => _.get(v, 'attempts[0]', undefined))
            .map((v, index, self) => {
              const totalScore = _.get(v, 'totalScore', 0);
              const time = _.get(v, 'totalTime', 0);
              const minutes = Math.floor(time / 60)
                .toString()
                .padStart(2, '0');
              const seconds = (time % 60).toString().padStart(2, '0');
              return (
                <table style={{ color: '#595959' }} key={index}>
                  <tbody>
                    {self.length > 1 && (
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            padding: '0rem 0rem',
                            fontWeight: 600,
                            fontSize: '0.78rem',
                          }}
                        >
                          {index === 0
                            ? 'Phần 1: Thi lý thuyết'
                            : 'Phần 2: Phần thi thực hành'}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td style={{ minWidth: 180, padding: '0.5rem 0rem' }}>
                        Thời gian thi:
                      </td>
                      <td style={{ fontWeight: '600', color: '#2b68a9' }}>
                        {minutes} phút {seconds} giây
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '0.5rem 0rem' }}>Điểm đạt được:</td>
                      <td style={{ fontWeight: '600', color: '#2b68a9' }}>
                        {Number(totalScore.toFixed(2))} điểm
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          {renderTotalTime()}
        </Space>
      ) : (
        <ul className="custom-dot">
          <li>
            <Text
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Thời gian thi:{' '}
              <span style={{ color: '#2b68a9', fontWeight: '600' }}>
                {`${
                  examQuestion?.startTime
                    ? moment(examQuestion.startTime).format('HH:mm DD/MM') +
                      ' - '
                    : ''
                }${
                  examQuestion?.endTime
                    ? moment(examQuestion.endTime).format('HH:mm DD/MM/YYYY')
                    : ''
                }`}
              </span>
              .
            </Text>
          </li>
          <li>
            <Text
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Tổng thời gian thi:{' '}
              <span style={{ color: '#2b68a9', fontWeight: '600' }}>
                {minutes} phút {seconds} giây
              </span>
              .
            </Text>
          </li>
          <li>
            <Text
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Số câu hỏi trong phần thi:{' '}
              <span style={{ color: '#2b68a9', fontWeight: '600' }}>
                {totalQuestionAmount} câu hỏi
              </span>
              .
            </Text>
          </li>
          <li>
            <Text
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Tổng điểm tối đa:{' '}
              <span style={{ color: '#2b68a9', fontWeight: '600' }}>
                {Number(totalPoint.toFixed(2))} điểm
              </span>
            </Text>
          </li>
          <li>
            <Text
              style={{
                color: COLORS.TEXT.yellowGrey,
                fontSize: '0.75rem',
                marginBottom: 12,
              }}
            >
              Mỗi thí sinh chỉ được tham gia 1 lần
            </Text>
            .
          </li>
        </ul>
      )}
      <div className="d-flex justify-content-center">
        <Button
          onClick={startExam}
          className="button-shadow"
          style={{
            background:
              'linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 50%)',
            height: 26,
            color: '#6E6601',
            fontSize: '0.8rem',
            borderRadius: '0.5rem',
            margin: '0 auto',
            fontWeight: '700',
          }}
          disabled={textButton === 'Mở sau' || creatingLtsExamAttempt}
          type={'ghost'}
        >
          {textButton === 'Đã đóng' ? (
            textButton
          ) : creatingLtsExamAttempt ? (
            'Đang tải...'
          ) : sectionsUnExam.length === 0 ? (
            'Đóng'
          ) : textButton === 'Mở sau' ? (
            <CountDown miliseconds={timeLeft * 1000} />
          ) : (
            textButton
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ModalSectionGroup);
