import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { KeyOutlined, PhoneOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as yup from 'yup';
import _ from 'lodash';
import { useHistory } from 'react-router';

import { RootState } from 'store';
import Loading from 'pages/components/Loading';

import bgUrl from 'assets/img/university_login_bg.png';
import bgMobileUrl from 'assets/img/university_mobile_login_bg.png';

import { PayloadType } from 'type';
import { loginRequest } from 'features/accountSlice';
import { isMobile } from 'utils';
import { COLORS } from 'style';
import UniversityText from 'pages/components/UniversityText';

const LoginUniversity: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const minWidth = isMobile() ? 240 : 330;
  const { loginning } = useSelector((state: RootState) => state.accountReducer);

  const [isRemember, setIsRemember] = useState<boolean>(
    JSON.parse(localStorage.getItem('isRemember') ?? 'true')
  );
  const auth = {
    phone: JSON.parse(localStorage.getItem('phone') ?? '""'),
    password: atob(JSON.parse(localStorage.getItem('password') ?? '""')),
  };

  const loginSchema = yup.object().shape({
    phone: yup
      .string()
      .required('Số điện thoại không được để trống!')
      .test('phone', 'Số điện thoại chỉ bao gồm số!', (phone: any): boolean => {
        const reg = /^\d+$/g;
        return reg.test(phone);
      }),
    password: yup.string().required('Mật khẩu không được để trống!'),
  });

  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };

  // const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const { name, value } = event.target;
  //   if (name === 'phone' && !isNumeric(value)) {
  //     return;
  //   }
  //   setAuth({
  //     ...auth,
  //     [name]: value,
  //   });
  // };

  const handleChecked = (event: CheckboxChangeEvent): void => {
    const { checked } = event.target;
    setIsRemember(checked);
  };

  const handleSubmit = async (values: {
    phone: string;
    password: string;
  }): Promise<void> => {
    const { phone, password } = values;
    if (isRemember) {
      localStorage.setItem('phone', JSON.stringify(phone));
      localStorage.setItem('password', JSON.stringify(btoa(password)));
      localStorage.setItem('isRemember', JSON.stringify(isRemember));
    }

    const payload: PayloadType = {
      body: {
        phone,
        password,
      },
      callback: {
        success(data) {
          const { tokens = null, user = null } = data;
          if (tokens === null || user === null) return;
          localStorage.setItem('contestant', JSON.stringify(user));
          localStorage.setItem(
            'access-token',
            JSON.stringify(_.get(tokens, 'access.token', ''))
          );
          localStorage.setItem(
            'refresh-token',
            JSON.stringify(_.get(tokens, 'refresh.token', ''))
          );
          // window.location.href = '/contestant/home';
          history.push('/contestant/home');
        },
        failed() {
          notification.error({
            message: 'Thông tin tài khoản hoặc mật khẩu không chính xác!',
          });
        },
      },
    };
    dispatch(loginRequest(payload));
  };
  const toRegister = (): void => {
    history.push('/auth/register');
  };

  return (
    <div
      // className="d-flex flex-column justify-content-center align-items-center"
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${isMobile() ? 
            bgUrl
            // bgMobileUrl 
            : bgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <UniversityText />
      <Form
        name="normal_login"
        initialValues={{ isRemember: isRemember, ...auth }}
        size={'middle'}
        onFinish={handleSubmit}
        style={{
          background: '#fff',
          width: minWidth + (isMobile() ? 60 : 150),
          borderRadius: isMobile() ? '0.8rem' : '1.2rem',
          margin: 'auto',
        }}
      >
        <Row style={{ margin: '0 auto', padding: '2vh 0px 4.2vh' }}>
          <Col
            style={{
              margin: '0 auto',
              minWidth: minWidth,
            }}
            xl={8}
            md={12}
            sm={18}
            xs={24}
          >
            <Space
              size={'small'}
              direction="vertical"
              className="d-flex justify-content-center align-items-center"
            >
              <p
                style={{
                  marginBottom: 0,
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontSize: 14,
                }}
                className="text-center"
              >
                Vui lòng đăng nhập để vào thi
              </p>
              <Input.Group>
                <Space size={'small'} direction="vertical">
                  <Form.Item name="phone" rules={[yupSync]}>
                    <Input
                      placeholder="Nhập số điện thoại"
                      prefix={<PhoneOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[yupSync]}>
                    <Input.Password
                      placeholder="Nhập mật khẩu"
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                      visibilityToggle={{
                        visible: false,
                      }}
                      prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                    />
                  </Form.Item>
                </Space>
              </Input.Group>
              <div style={{ minWidth: minWidth }}>
                <Checkbox
                  name="isRemember"
                  checked={isRemember}
                  onChange={handleChecked}
                >
                  Ghi nhớ đăng nhập
                </Checkbox>
              </div>

              <Button
                style={{ minWidth: minWidth }}
                htmlType="submit"
                type="primary"
              >
                Đăng nhập
              </Button>
              <div
                className="d-flex flex-row justify-content-end"
                style={{
                  minWidth: minWidth,
                  color: COLORS.TEXT.yellowGrey,
                  fontWeight: '500',
                  fontSize: 14,
                }}
              >
                Chưa có tài khoản?&nbsp;
                <span
                  onClick={toRegister}
                  style={{ cursor: 'pointer', color: COLORS.TEXT.primary }}
                >
                  Đăng ký ngay
                </span>
              </div>
            </Space>
          </Col>
        </Row>
      </Form>
      {loginning && (
        <div
          style={{
            top: 0,
            paddingTop: '35vh',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(255,255,255,.3)',
            position: 'absolute',
          }}
        >
          <Loading />
        </div>
      )}
    </div>
  );
};

export default LoginUniversity;
