import React, { useEffect } from 'react';
import { Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { RootState } from 'store';
import { PayloadType, SectionGroup } from 'type';

import bgUrl from 'assets/img/login_bg.png';
import { COLORS } from 'style';
import CardTest from './components/CardTest';
import { getSectionGroupsWithInfoRequest } from 'features/sectionGroupSlice';
import queryString from 'query-string';

const { Title, Text } = Typography;

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const { sectionGroupsWithInfo } = useSelector(
    (state: RootState) => state.sectionGroupSlice
  );

  const results: SectionGroup[] = _.get(sectionGroupsWithInfo, 'results', []);

  useEffect(() => {
    const payload: PayloadType = {
      callback: {
        success() {
          //
        },
        failed() {
          //
        },
      },
      query: queryString.stringify({
        // populate: 'sections',
        limit: 1000,
      }),
    };

    dispatch(getSectionGroupsWithInfoRequest(payload));
  }, []);

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        backgroundImage: `url(${bgUrl})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        padding: '16px 8px ',
      }}
    >
      <Title
        style={{
          textTransform: 'uppercase',
          textAlign: 'center',
          color: COLORS.TEXT.fail,
          marginBottom: 0,
        }}
        className="text-center"
        level={5}
      >
        TỰ HÀO VIỆT NAM
        {/* TRẠI HUẤN LUYỆN KIM ĐỒNG CẤP I TOÀN QUỐC NĂM 2024 */}
      </Title>
      <Text
        className="text-center"
        style={{
          color: COLORS.TEXT.fail,
          display: 'table',
          margin: '0 auto',
        }}
        strong
      >
        (Vui lòng chọn phần thi)
      </Text>

      <Row style={{ maxWidth: 1024, margin: '0px auto' }} gutter={8}>
        {results.map((item: SectionGroup, index: number): JSX.Element => {
          return <CardTest item={item} key={index} />;
        })}
      </Row>
    </div>
  );
};

export default Home;
