import React from 'react';
import Icon from '@ant-design/icons';

import { ReactComponent as LogoHauiSvg } from 'assets/svgs/logo_haui.svg';
import { ReactComponent as LogoDoanSvg } from 'assets/svgs/logo_doan.svg';
import { isMobile } from 'utils';

const UniversityText: React.FC = () => {
  return isMobile() ? (
    <>
      <h3 className="login-mobile-title-1">
        {/* KỶ NIỆM 125 NĂM TRUYỀN THỐNG<br></br>ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI
        (1898-2024) */}
      </h3>
      <div
        style={{
          margin: '3.2vh 0px',
        }}
        className="d-flex justify-content-center align-item-center"
      >

        <Icon
          style={{ fontSize: '3.2rem', marginRight: '1rem' }}
          component={LogoHauiSvg}
        />
        <Icon
          style={{ fontSize: '3.2rem', marginRight: '1rem' }}
          component={LogoDoanSvg}
        />
      </div>
      <p className="text-center login-mobile-title-2">
        TRƯỜNG ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI<br></br>ĐOÀN THANH NIÊN TRƯỜNG ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI
        {/* TRUNG TÂM HỖ TRỢ SINH VIÊN */}
      </p>
      <p className="text-center login-mobile-title-3">
        {/* SINH VIÊN ĐHCNHN<br></br>VỚI CÔNG TÁC PHÒNG, CHỐNG MA TÚY */}
        TỰ HÀO VIỆT NAM
      </p>
    </>
  ) : (
    <>
      <h1 className="text-center login-title-1">
        {/* KỶ NIỆM 125 NĂM TRUYỀN THỐNG ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI (1898-2024) */}
      </h1>
      <div
        style={{
          margin: '3.2vh 0px',
        }}
        className="d-flex justify-content-center align-item-center"
      >
        <Icon
          style={{ fontSize: '5rem', marginRight: '1rem' }}
          component={LogoHauiSvg}
        />
        <Icon
          style={{ fontSize: '5rem', marginRight: '1rem' }}
          component={LogoDoanSvg}
        />
      </div>
      <p className="text-center login-title-2">
        TRƯỜNG ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI<br></br>ĐOÀN THANH NIÊN TRƯỜNG ĐẠI HỌC CÔNG NGHIỆP HÀ NỘI
      </p>
      <p className="text-center login-title-3">
        TỰ HÀO VIỆT NAM
      </p>
    </>
  );
};

export default UniversityText;
