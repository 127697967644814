import { IS_UNIVERSITY } from './services/ServiceURL';
import Home from 'pages/Home';
import Login from 'pages/Login';
import MultipleChoice from 'pages/Exam';
import Register from 'pages/Register';
import Result from 'pages/Result';
import LoginUniversity from 'pages/LoginUniversity';
import RegisterUniversity from 'pages/RegisterUniversity';

export interface RouteItem {
  hidden?: boolean; //VD: Ẩn các route cho màn thêm, sửa
  collapse?: boolean;
  state?: string; // các route sẽ nhớ và active lại khi f5: [state,path+name]
  name?: string;
  path?: string;
  icon?: any;
  component?: React.FunctionComponent;
  layout?: string;
  roles?: [];
  views?: RouteItem[]; // yêu cầu collapse = true
}

// Route Auth
const routesA: RouteItem[] = [
  {
    collapse: true,
    name: 'Auth',
    state: 'authCollapse',
    views: [
      {
        path: '/login',
        name: 'Đăng nhập',
        component: IS_UNIVERSITY ? LoginUniversity : Login,
        layout: '/auth',
        roles: [],
      },
      {
        path: '/register',
        name: 'Đăng ký',
        component: IS_UNIVERSITY ? RegisterUniversity : Register,
        layout: '/auth',
        roles: [],
      },
    ],
  },
];

const routesCL: RouteItem[] = [
  {
    name: 'Trang chủ',
    path: '/home',
    component: Home,
    layout: '/contestant',
    roles: [],
  },
  {
    path: '/exam-attempts/:id',
    component: MultipleChoice,
    layout: '/contestant',
    roles: [],
  },
  {
    path: '/results/:id',
    component: Result,
    layout: '/contestant',
    roles: [],
  },
];

export default {
  routesA,
  routesCL,
};
