import React from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  notification,
  Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserOutlined,
  KeyOutlined,
  PhoneOutlined,
  FieldNumberOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';
import { useHistory } from 'react-router';

import { RootState } from 'store';
import Loading from 'pages/components/Loading';

import bgUrl from 'assets/img/university_login_bg.png';
import bgMobileUrl from 'assets/img/university_mobile_login_bg.png';
import { COLORS } from 'style';
import { PayloadType } from 'type';
import { registerRequest } from 'features/accountSlice';
import { MAJOR } from 'const';
import { isMobile } from 'utils';
import UniversityText from 'pages/components/UniversityText';

const { Text } = Typography;

interface Info {
  name: string;
  idNum: string;
  major: null | string;
  phone: string;
  password: string;
  rePassword: string;
}

const RegisterUniversity: React.FC = () => {
  const minWidth = isMobile() ? 240 : 330;

  const history = useHistory();
  const dispatch = useDispatch();

  const { registering } = useSelector(
    (state: RootState) => state.accountReducer
  );

  const [form] = Form.useForm();

  const init: Info = {
    name: '',
    idNum: '',
    phone: '',
    major: null,
    password: '',
    rePassword: '',
  };

  const infoSchema = yup.object().shape({
    name: yup.string().required('Tên thí sinh không được để trống!'),
    idNum: yup
      .string()
      .test('phone', 'Mã sinh viên chỉ bao gồm số!', (phone: any): boolean => {
        const reg = /^\d+$/g;
        return reg.test(phone);
      })
      .required('Mã sinh viên không được để trống!'),
    phone: yup
      .string()
      .required('Số điện thoại không được để trống!')
      .test('phone', 'Số điện thoại chỉ bao gồm số!', (phone: any): boolean => {
        const reg = /^\d+$/g;
        return reg.test(phone);
      })
      .length(10, 'Số điện thoại chỉ gồm 10 số!'),
    major: yup
      .string()
      .test('major', 'Đơn vị không được để trống!', (major: any): boolean => {
        const reg = /^\d+$/g;
        return reg.test(major);
      })
      .required('Đơn vị không được để trống!')
      .nullable(),
    position: yup.string().required('Chức vụ không được để trống!'),
    password: yup
      .string()
      .test(
        'password',
        'Mật khẩu phải ít nhất 1 kí tự bao gồm chữ và số!',
        (password: any): boolean => {
          const reg = /^(?=.*[a-zA-Z])(?=.*\d).+$/g;
          return reg.test(password);
        }
      )
      .required('Mật khẩu không được để trống!'),
  });

  const yupSync = {
    async validator({ field }: any, value: any) {
      await infoSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const validateConfirmPassword = ({ getFieldValue }: any): any => ({
    validator(_: any, value: any) {
      if (value === '') {
        return Promise.reject(new Error('Vui lòng xác nhận mật khẩu!'));
      }
      if (getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Mật khẩu không trùng khớp!'));
    },
  });

  // const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const { name, value } = event.target;
  //   if (name === 'phone' && !isNumeric(value)) {
  //     return;
  //   }
  //   setInfo({
  //     ...info,
  //     [name]: value,
  //   });
  // };

  // const handleOnChangeSelectMajor = (
  //   value: number,
  //   option: { label: string; value: number }
  // ): void => {
  //   console.log('option: ', option);
  //   setInfo({
  //     ...info,
  //     major: option.label,
  //   });
  // };

  const handleSubmit = async (values: Info): Promise<void> => {
    const { name, password, major, phone, idNum } = values;
    const majorLabel =
      MAJOR.find((item) => item.value === parseInt(major ?? '-1'))?.label ?? '';

    const payload: PayloadType = {
      body: {
        name,
        password,
        major: majorLabel,
        phone,
        idNum,
      },
      callback: {
        success() {
          notification.success({
            message: 'Đăng ký tài khoản thành công!',
          });
          setTimeout(() => {
            history.push('/auth/login');
          }, 1000);
        },
        failed(message) {
          notification.error({
            message,
          });
        },
      },
    };
    dispatch(registerRequest(payload));
  };

  const toLogin = (): void => {
    history.push('/auth/login');
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${isMobile() ? 
            // bgMobileUrl 
            bgUrl
            : bgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <UniversityText />
      <Form
        name={'normal_info'}
        initialValues={init}
        size={'middle'}
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={(error) => {
          // console.log(error);
        }}
        style={{
          background: '#fff',
          width: minWidth + (isMobile() ? 60 : 150),
          borderRadius: isMobile() ? '0.8rem' : '1.2rem',
          margin: 'auto',
        }}
      >
        <Row style={{ margin: '0 auto', padding: '2vh 0px 2.4vh' }}>
          <Col
            style={{
              margin: '0 auto',
            }}
            xl={8}
            md={12}
            sm={18}
            xs={24}
          >
            <Space
              size={'small'}
              direction="vertical"
              className="d-flex justify-content-center align-items-center"
              style={{
                flexDirection: 'column',
              }}
            >
              <Text
                className="text-center"
                style={{
                  color: COLORS.TEXT.primary,
                  display: 'table',
                  margin: 'auto',
                }}
              >
                Đăng ký tài khoản mới
              </Text>
              <Input.Group>
                <Space size={0} direction="vertical">
                  <Form.Item name="name" rules={[yupSync]}>
                    <Input
                      placeholder="Họ và tên"
                      prefix={<UserOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="idNum" rules={[yupSync]}>
                    <Input
                      placeholder="Mã sinh viên"
                      prefix={<FieldNumberOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="major" rules={[yupSync]}>
                    <Select
                      placeholder={'Chọn đơn vị'}
                      defaultValue={null}
                      className=""
                      style={{}}
                      options={MAJOR}
                    />
                  </Form.Item>
                  <Form.Item name="phone" rules={[yupSync]}>
                    <Input
                      placeholder="Số điện thoại(dùng để đăng nhập)"
                      prefix={<PhoneOutlined style={{ fontSize: 18 }} />}
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ maxWidth: minWidth }}
                    name="password"
                    rules={[yupSync]}
                  >
                    <Input.Password
                      placeholder="Nhập mật khẩu"
                      autoComplete="off"
                      style={{
                        maxWidth: minWidth,
                      }}
                      visibilityToggle={{
                        visible: false,
                      }}
                      prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rePassword"
                    rules={[validateConfirmPassword]}
                  >
                    <Input.Password
                      placeholder="Xác nhận mật khẩu"
                      autoComplete="off"
                      style={{
                        width: minWidth,
                      }}
                      visibilityToggle={{
                        visible: false,
                      }}
                      prefix={<KeyOutlined style={{ fontSize: 18 }} />}
                    />
                  </Form.Item>
                </Space>
              </Input.Group>
              <Button
                style={{ minWidth: minWidth }}
                htmlType="submit"
                type="primary"
              >
                Đăng ký
              </Button>
              <div
                className="d-flex flex-row justify-content-end"
                style={{
                  minWidth: minWidth,
                  color: COLORS.TEXT.yellowGrey,
                  fontWeight: '500',
                  fontSize: 12,
                }}
              >
                Đã có tài khoản?&nbsp;
                <span
                  onClick={toLogin}
                  style={{ cursor: 'pointer', color: COLORS.TEXT.primary }}
                >
                  Quay lại đăng nhập
                </span>
              </div>
            </Space>
          </Col>
        </Row>
      </Form>
      {registering && (
        <div
          style={{
            top: 0,
            paddingTop: '35vh',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(255,255,255,.3)',
            position: 'absolute',
          }}
        >
          <Loading />
        </div>
      )}
    </div>
  );
};

export default RegisterUniversity;
